import React from "react";
import { Helmet as Hel } from "react-helmet";

function Helmet({ title, metaDescription, slug, keywords }) {
  

  const afTags = () => {
    var sc = document.createElement("script");
    sc.type = "text/javascript";
    sc.async = true;
    sc.src = "//customs.affilired.com/track/?merchant=4796";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(sc, s);
  };
  // const url = window.location.href;
  let url = window.location.href;  
  url = url.endsWith("/") ? url.slice(0, -1) : url;
  const domainName = new URL(url).hostname;
  return (
    <Hel>
      {title ? <title>{title}</title> : <title>Raaya By Atmosphere</title>}
      {(domainName.includes("uat") || domainName.includes("stg") || domainName.includes("digitechsoft") || domainName.includes("digitech")  || domainName.includes("stgdigitech") )&& (
        <meta name="robots" content="noindex, nofollow" />
      )}
      <link rel="canonical" href={url} />

      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={keywords} />

      {/* script start  */}

      <script type="text/javascript">
        {`(function() {
  var sc = document.createElement('script'); sc.type = 'text/javascript'; sc.async = true;
  sc.src = '//customs.affilired.com/track/?merchant=4796';
  var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(sc, s);
  })();`}
      </script>
      {/* script end   */}
    </Hel>
  );
}

// slug: "test-offer-121"
// keywords: "hello keywords"
// meta_title: "hello meta title"
// meta_description: "hello meta description"

export default Helmet;
