import React from "react";
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import ExperienceTabItem from "./ExperienceTabItem";
import { color } from "framer-motion";
import tabImageView from '../../assets/images/raaya-placeholder.jpg'

import CssFilterConverter from 'css-filter-converter';
import Image from "../../components/Image";


function ExperienceTabs({ pagesSectionData }) {
  const [activeTab, setActiveTab] = React.useState(0);
  const [activeIndex, setIndex] = React.useState(0);
  const [hoverStyle, setHoverStyle] = React.useState({});

  const onTabClick = (e, index) => {
    setActiveTab(index);
  };
  const tabsData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "TABS"
      )[0] ?? null
  );
  const subSectionData = tabsData?.[0]?.subSectionData;
  return (
    <>
    <div className="container tabs-container">
                    <div className="row">
                      <div className="col-12">
      <Tabs
        activeTab={activeTab}
        onTabClick={onTabClick}
        tabsArray={subSectionData}
        tabsScrollAmount={1}
      >
        {subSectionData?.map((item, index) => {
          let bgcolor =
            item.elements.filter(
              (name) => name.field_title_code === "COLORCODE"
            )[0]?.data ?? null;
          const root = document.documentElement;

          // root.style.setProperty('--light-see-green', bgcolor);

          const color = {
            background: bgcolor,

            borderTopColor: `${bgcolor} !important`,
          };
          
          function handelHover(index) {
            const style = {
              border: `1px solid ${bgcolor}`,
            };
            setHoverStyle(style);
            setIndex(index);
          }
          function handleMouseLeave() {
            setHoverStyle({});
            setIndex(activeTab); // Reset active index to the currently active tab
          }
          if (activeTab === index) {
            root.style.setProperty("--light-see-green", bgcolor);
          }
          const filteredStyle = CssFilterConverter.hexToFilter(bgcolor).color;
         
          return (
            
            <Tab
              onMouseEnter={() => handelHover(index)}
              onMouseLeave={handleMouseLeave}
              key={index}
              className={`${item.tabNavBrand} item ${
                activeTab === index
                  ? "rts___tab rts___btn p-0 diving-nav item active text-uppercase d-flex align-items-center rts___tab___selected border-0"
                  : "text-uppercase d-flex align-items-center p-0 border-0"
              }`}
              // style={{
              //   ...(index === activeIndex ? hoverStyle : ""),
              //   ...(activeTab === index ? color : ""),
              // }}
            >
              <div className="position-relative tab-contain">
              <span className="tab-feature-img position-absolute d-flex rounded-circle overflow-hidden bg-white"
            // style={{
            //     ...(activeTab === index ? { backgroundColor: "white" } : {backgroundColor: bgcolor}),
            //   }}
            
            >
               <img
                  src={
                    item.elements.filter(
                      (image) => image.field_title_code === "ICON"
                    )[0]?.data ?? null
                  }
                  style={{ filter: filteredStyle }}
                  // style={{
                  //   ...(activeTab === index ? {filter : filteredStyle }  : ""),
                  // }}
                
                  alt=""
                  title=""
                />
              </span>
              <div 
               style={{ backgroundColor: bgcolor }}
              className="gradnt-brdr d-flex justify-content-center align-items-center position-absolute start-0 bottom-0 w-100">
              <span
                className="tab-name position-relative text-uppercase w-100 d-flex justify-content-center align-items-center h-100 text-white"
              //   style={{
              //     ...(index === activeIndex ? {color:bgcolor} : ""),
              //  }}
              style={{ backgroundColor: bgcolor }}
               >
           
             {item.elements.filter(
               (name) => name.field_title_code === "NAME"
             )[0]?.data ?? null}
              </span></div>
              <div className="w-100 exprnc-tab-img">
              <Image
                  src={item?.elements?.filter(
                    (image) => image?.field_title_code === "IMAGE"
                  )[0]?.data ||tabImageView}

                  alt={item?.elements?.filter(
                    (image) => image?.field_title_code === "IMAGE"
                  )[0]?.img_alt_tag ?? null}

                  onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // Prevents looping
                  currentTarget.src = tabImageView; // Replace with the path to your dummy image
                  }}
                  className="w-100"

                />
              </div>
              </div>
            </Tab>
          );
        })}
      </Tabs>
      </div>
      </div>
      </div>

      {subSectionData?.map((screen, index) => (
        <div className="container tabs-container">
        <div className="row">
          <div className="col-12"><TabScreen
          key={index}
          // key={+screen.page_sub_section_id}
          activeTab={activeTab}
          index={index}
        >
          <ExperienceTabItem
            //  tabBrand={screen.tabBrand}
            colorCode={
              screen.elements.filter(
                (item) => item.field_title_code === "COLORCODE"
              )[0]?.data ?? null
            }
            tabMainHeading={
              screen.elements.filter(
                (item) => item.field_title_code === "TITLE"
              )[0]?.data ?? null
            }
            tabAboutPara={
              screen.elements.filter(
                (item) => item.field_title_code === "DESCRIPTION"
              )[0]?.data ?? null
            }
            ExperienceTabItem={screen.children?.map((i) => i.elements)}
          />
        </TabScreen>
        </div>
        </div>
        </div>
      ))}
    </>
  );
}

export default ExperienceTabs;
