import React,{useState} from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

import ExperienceAwait from "../../components/ExperienceAwait";

import Glimpses from "../../components/Glimpses";
import GalleryView from "./GalleryView";

import { getPageData,getPageData1 } from "../../services/dataServices";
import { useQuery } from "react-query";
import bannerCover from "../../assets/defaultBannerAndIcon/banner-placehoder.jpg";

import Helmet from "../../components/Helmet";


import { useContext } from "react";
import { MyContext } from "../../Contex/LoadingContex";
import Loader from "../../components/Loader";
import BannerImage from "../../components/BannerImage";
function OurGallery({ isPreview, userId, sectionId,slugOfGallery }) {
  const pageCode = "RGALLERY";
  const pagesSectionData1 = useQuery(
    ["pagesSectionData1", pageCode, isPreview, userId, sectionId],
    () => getPageData1(pageCode, isPreview, userId, sectionId)
  );
  const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);

  const {isLoading,isSuccess}=pagesSectionData1




  const galleryImage =
    pagesSectionData1?.data?.sectionData?.filter((item) => item.section_code === "MAINBANNER" && item.field_label_code === "BANNERIMAGE"
      )[0]?.psdd_data ?? bannerCover;

  const pagesSectionData = useQuery(
    ["pagesSectionData", pageCode, isPreview, userId, sectionId],
    () => getPageData(pageCode, isPreview, userId, sectionId)
  );
  // const [isBannerLoading, setIsBannerLoading] = useState(true);

  // const handleImageLoad = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // const handleError = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // if (isLoading) {
  //   setIsLoadingMain(true);
  //   document.body.classList.add('slider-open');
  //   return <Loader />;
  // }
  // else if (isBannerLoading) {
  //   document.body.classList.add('slider-open');
  //   setIsLoadingMain(true);
  // }
 
  const galleryData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "MAINBANNER"
      )[0] ?? null
  );


    const bannerGalleryImageAltTag =
    galleryData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "BANNERIMAGE"
    )[0]?.img_alt_tag ?? null;

  const ExperienceAwaitData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "GLIMPSES"
      )[0] ?? null
  );
  const ExperienceAwaitIcon =
    ExperienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "ICON"
    )[0]?.data ?? null;
  const ExperienceAwaitHeading =
    ExperienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "HEADING"
    )[0]?.data ?? null;
  const ExperienceAwaitDescription =
    ExperienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "DESCRIPTION"
    )[0]?.data ?? null;

  const Image1 =
    ExperienceAwaitData?.[0]?.subSectionData
      ?.map((item) => item.elements)?.[0]
      ?.filter((items) => items.field_title_code === "IMAGE5")[0]?.data ?? null;

  const Image2 =
    ExperienceAwaitData?.[0]?.subSectionData
      ?.map((item) => item.elements)?.[0]
      ?.filter((items) => items.field_title_code === "IMAGE2")[0]?.data ?? null;

  const Image3 =
    ExperienceAwaitData?.[0]?.subSectionData
      ?.map((item) => item.elements)?.[0]
      ?.filter((items) => items.field_title_code === "IMAGE4")[0]?.data ?? null;

  const Image4 =
    ExperienceAwaitData?.[0]?.subSectionData
      ?.map((item) => item.elements)?.[0]
      ?.filter((items) => items.field_title_code === "IMAGE3")[0]?.data ?? null;

  const Image5 =
    ExperienceAwaitData?.[0]?.subSectionData
      ?.map((item) => item.elements)?.[0]
      ?.filter((items) => items.field_title_code === "IMAGE6")[0]?.data ?? null;

  const Image6 =
    ExperienceAwaitData?.[0]?.subSectionData
      ?.map((item) => item.elements)?.[0]
      ?.filter((items) => items.field_title_code === "IMAGE1")[0]?.data ?? null;


      const topLeftImagealt =
      ExperienceAwaitData?.[0]?.subSectionData
      ?.map((item) => item.elements)?.[0]
      ?.filter((items) => items.field_title_code === "IMAGE1")[0]?.img_alt_tag ?? null;
  
      const topRightImagealt =
      ExperienceAwaitData?.[0]?.subSectionData
      ?.map((item) => item.elements)?.[0]
      ?.filter((items) => items.field_title_code === "IMAGE5")[0]?.img_alt_tag ?? null;
  
      const bottomLeftImagealt=
      ExperienceAwaitData?.[0]?.subSectionData
      ?.map((item) => item.elements)?.[0]
      ?.filter((items) => items.field_title_code === "IMAGE2")[0]?.img_alt_tag ?? null;
  
      const bottomCenterImagealt =
      ExperienceAwaitData?.[0]?.subSectionData
      ?.map((item) => item.elements)?.[0]
      ?.filter((items) => items.field_title_code === "IMAGE4")[0]?.img_alt_tag ?? null;
  
      const topCenterImagealt =
      ExperienceAwaitData?.[0]?.subSectionData
      ?.map((item) => item.elements)?.[0]
      ?.filter((items) => items.field_title_code === "IMAGE3")[0]?.img_alt_tag ?? null;
  
      const bottomRightImagealt =
      ExperienceAwaitData?.[0]?.subSectionData
      ?.map((item) => item.elements)?.[0]
      ?.filter((items) => items.field_title_code === "IMAGE6")[0]?.img_alt_tag ?? null;




  const myriadData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "MYRIAD"
      )[0] ?? null
  );
  const myriadHeading =
    myriadData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "HEADING"
    )[0]?.data ?? null;

  let keywords = pagesSectionData?.data?.data[0]?.meta_keyword;
  let slug = pagesSectionData?.data?.data[0]?.slug;
  let meta_title = pagesSectionData?.data?.data[0]?.meta_title;
  let meta_description = pagesSectionData?.data?.data[0]?.meta_description;

  return (
    <>
      <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />

      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100 cover-overlay">
            {/* <img className="w-100 h-100" src={galleryImage} title="" alt={bannerGalleryImageAltTag} onLoad={handleImageLoad} onError={handleError}/> */}
            <BannerImage
              className="w-100 h-100"
              src={galleryImage}
              title=""
              alt={bannerGalleryImageAltTag}
              isDataIsLoading={isLoading}
            />
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                Gallery
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Gallery</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>
      <ExperienceAwait
        image={ExperienceAwaitIcon}
        showLargeheading={true}
        showHeading={false}
        heading={ExperienceAwaitHeading}
        para={ExperienceAwaitDescription}
      />
      <Glimpses
        showGlimpsesHeader={false}
        image1={Image1}
        image2={Image2}
        image3={Image3}
        image4={Image4}
        image5={Image5}
        image6={Image6}

        bottomLeftImagealt={bottomLeftImagealt}
        topRightImagealt={topRightImagealt}
        bottomCenterImagealt={bottomCenterImagealt}
        topCenterImagealt={topCenterImagealt}
        bottomRightImagealt={bottomRightImagealt}
        topLeftImagealt={topLeftImagealt}

        slugOfGallery={slugOfGallery}

      />

      <section>
        <div className="our-gallery section-padding pt-0">
          <div className="container">
            <div className="row mb-lg-5 mb-4">
              <div className="col-12">
                <div className="hotel-heading-wrap d-flex align-items-center justify-content-between px-0">
                  <h2 className="h1 text-uppercase georgia heading-space mb-0">
                    {myriadHeading}
                  </h2>
                </div>
              </div>
            </div>
            <div className="gallery-slide-cards">
              <GalleryView galleryCardData={myriadData} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurGallery;
